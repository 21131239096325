import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Android SDK v2 Releases`}</h1>
    <h2>{`2.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Migrate to Enigmatic-Smile GitHub organization`}</li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <ul>
      <li parentName="ul">{`No relevant changes for this product`}</li>
    </ul>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Add a few more card verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Removed card scanning so `}<inlineCode parentName="li">{`Fidel.autoScan`}</inlineCode>{` has been removed.`}</li>
      <li parentName="ul">{`Changes in the `}<inlineCode parentName="li">{`Fidel`}</inlineCode>{` class:`}
        <ul parentName="li">
          <li parentName="ul">{`Made the `}<inlineCode parentName="li">{`companyName`}</inlineCode>{` property mandatory`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.apiKey`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`Fidel.sdkKey`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.termsConditionsURL`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`Fidel.termsAndConditionsUrl`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Fidel.privacyURL`}</inlineCode>{` was renamed to `}<inlineCode parentName="li">{`privacyPolicyUrl`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`getInstance()`}</inlineCode>{` has been removed`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.present(...)`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`Fidel.start(...)`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Country`}</inlineCode>{`, `}<inlineCode parentName="li">{`ProgramType`}</inlineCode>{` and `}<inlineCode parentName="li">{`CardScheme`}</inlineCode>{` have been moved out of the `}<inlineCode parentName="li">{`Fidel`}</inlineCode>{` class.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onActitivityResult`}</inlineCode>{` will no longer be used to get results.`}</li>
      <li parentName="ul">{`Instead of providing just one type of result, `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{`, the SDK now provides a generic `}<inlineCode parentName="li">{`Result`}</inlineCode>{` sealed class which incorporates different types of results: `}<inlineCode parentName="li">{`EnrollementResult`}</inlineCode>{`, `}<inlineCode parentName="li">{`VerificationSuccessful`}</inlineCode>{` (not relevant for Select Transaction API), `}<inlineCode parentName="li">{`ErrorResult`}</inlineCode>{`. Each provides the appropriate objects for developers: `}<inlineCode parentName="li">{`EnrollementResult`}</inlineCode>{`, `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Changes in the `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{` class:`}
        <ul parentName="li">
          <li parentName="ul">{`Rename to `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode></li>
          <li parentName="ul">{`Rename `}<inlineCode parentName="li">{`id`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardId`}</inlineCode></li>
          <li parentName="ul">{`The data type for `}<inlineCode parentName="li">{`created`}</inlineCode>{` property is now `}<inlineCode parentName="li">{`Date`}</inlineCode>{` not `}<inlineCode parentName="li">{`String`}</inlineCode>{`. Renamed the property to `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The data type for `}<inlineCode parentName="li">{`scheme`}</inlineCode>{` property is now `}<inlineCode parentName="li">{`CardScheme`}</inlineCode>{`, not `}<inlineCode parentName="li">{`String`}</inlineCode>{`. Renamed property to `}<inlineCode parentName="li">{`cardScheme`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed property `}<inlineCode parentName="li">{`live`}</inlineCode>{` to `}<inlineCode parentName="li">{`isLive`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardFirstNumbers`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`lastNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardLastNumbers`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`expYear`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`expMonth`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`expDate`}</inlineCode>{` string property has been removed.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`countryCode`}</inlineCode>{` property was renamed to `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode>{` and its data type is now `}<inlineCode parentName="li">{`Country`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`error`}</inlineCode>{` property and the `}<inlineCode parentName="li">{`getError`}</inlineCode>{` function have been removed.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Changes to the `}<inlineCode parentName="li">{`LinkResultError`}</inlineCode>{` class:`}
        <ul parentName="li">
          <li parentName="ul">{`Renamed to `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`date`}</inlineCode>{` property now has a `}<inlineCode parentName="li">{`Date`}</inlineCode>{` data type, not `}<inlineCode parentName="li">{`String`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The (error) code property has been renamed to `}<inlineCode parentName="li">{`type`}</inlineCode>{` and it is now a sealed class. It has 2 cases: `}<inlineCode parentName="li">{`EnrollmentError`}</inlineCode>{` and `}<inlineCode parentName="li">{`VerificationError`}</inlineCode>{` (not relevant for Select Transaction API)`}</li>
          <li parentName="ul">{`Each error type now has a subtype.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.onMainActivityCreate(Activity)`}</inlineCode>{` needs to be called at the end of the setup.`}</li>
      <li parentName="ul">{`The new package name is `}<inlineCode parentName="li">{`com.fidelapi`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`com.fidel.sdk`}</inlineCode>{` for all the SDK classes.`}</li>
    </ul>
    <h1>{`Android SDK v1 Releases`}</h1>
    <h2>{`1.7.5`}</h2>
    <ul>
      <li parentName="ul">{`Updated the consent text for United States and Canadian issued cards.`}</li>
    </ul>
    <h2>{`1.7.4`}</h2>
    <ul>
      <li parentName="ul">{`Allow expiration date editing without switching to country selection.`}</li>
    </ul>
    <h2>{`1.7.3`}</h2>
    <ul>
      <li parentName="ul">{`Replace "Fidel" with "Fidel API" in the consent text.`}</li>
    </ul>
    <h2>{`1.7.2`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel API logo`}</li>
    </ul>
    <h2>{`1.7.1`}</h2>
    <ul>
      <li parentName="ul">{`Add support for the `}<inlineCode parentName="li">{`resConfigs`}</inlineCode>{` optimization parameter. Now, if your app attempts to optimize resources and set the `}<inlineCode parentName="li">{`resConfigs`}</inlineCode>{` parameter in gradle (which might remove some of our string resources), our SDK will display either strings in the default language (English) or in one of the languages supported by your app. This also depends on the device language and how the Android system resolves string resources.`}</li>
    </ul>
    <h2>{`1.7.0`}</h2>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`defaultSelectedCountry`}</inlineCode>{` property which sets the country that will be selected by default, when opening the card enrollment screen.`}</li>
    </ul>
    <h2>{`1.6.0`}</h2>
    <ul>
      <li parentName="ul">{`Removed the card scanning confirmation screen. Users can confirm their card information by checking the information in the Fidel card enrollment screen.`}</li>
    </ul>
    <h2>{`1.5.6`}</h2>
    <ul>
      <li parentName="ul">{`Add United Arab Emirates option as a country of issuance.`}</li>
      <li parentName="ul">{`Country text view shrinks its fonts size, to fit longer country names, on smaller devices.`}</li>
    </ul>
    <h2>{`1.5.5`}</h2>
    <ul>
      <li parentName="ul">{`Improve French and Swedish language translations to cover more countries.`}</li>
    </ul>
    <h2>{`1.5.4`}</h2>
    <ul>
      <li parentName="ul">{`Improvements to allow more automation for quality assurance and speed of new SDK version delivery.`}</li>
    </ul>
    <h2>{`1.5.3`}</h2>
    <ul>
      <li parentName="ul">{`Improved compatibility with Kotlin projects`}</li>
      <li parentName="ul">{`Updated dependencies`}</li>
    </ul>
    <h2>{`1.5.2`}</h2>
    <ul>
      <li parentName="ul">{`Improved the user experience by making the form static when switching the focus from one text input field to another. Previously the form was scrolling to show the focused text field on top of the screen. For this reason the banner image was hidden when display the card linking activity. Now the banner image is always visible and we do no scrolling animations when switching the focus from one text field to another.`}</li>
    </ul>
    <h2>{`1.5.1`}</h2>
    <ul>
      <li parentName="ul">{`If available, the LinkResult object now includes the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field. So, if in the Fidel Dashboard, under the your security settings, you allow showing the first numbers of the linked card numbers, the information will be available in the LinkResult object too. If you do not allow showing the first numbers in the linking result, the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field will return `}<inlineCode parentName="li">{`"******"`}</inlineCode>{` (just like the object which the Fidel API returns).`}</li>
    </ul>
    <h2>{`1.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Now the SDK allows you to select multiple allowed countries from which the user can pick. Please check the docs for the new `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property.`}</li>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property. To set a default country and not allow the user to pick the country, set a single country in the new `}<inlineCode parentName="li">{`Fidel.allowedCountries`}</inlineCode>{` array.`}</li>
    </ul>
    <h2>{`1.4.0`}</h2>
    <ul>
      <li parentName="ul">{`Localised the SDK for French and Swedish users.`}</li>
      <li parentName="ul">{`The terms & conditions text now adjusts to the card scheme name that the user inputs (Visa, MasterCard or Amex).`}</li>
      <li parentName="ul">{`If you set the default country of the SDK to USA or Canada (with `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property) or, if you do not set a default country, the terms and conditions text will adjust depending on the country you have set. For USA & Canada, the following would be an example Terms & Conditions text, for Cashback Inc (an example company):`}</li>
    </ul>
    <p><em parentName="p">{`By submitting your card information and checking this box, you authorize Visa to monitor and share transaction data with Fidel (our service provider) to participate in  program. You also acknowledge and agree that Fidel may share certain details of your qualifying transactions with Cashback Inc to enable your participation in  program and for other purposes in accordance with the Cashback Inc Terms and Conditions, Cashback Inc privacy policy and Fidel’s Privacy Policy. You may opt-out of transaction monitoring on the linked card at any time by contacting support.`}</em></p>
    <p>{`For the rest of the world:`}</p>
    <p><em parentName="p">{`I authorise Visa to monitor my payment card to identify transactions that qualify for a reward and for Visa to share such information with Cashback Inc, to enable my card linked offers and target offers that may be of interest to me. For information about Cashback Inc privacy practices, please see the privacy policy. You may opt-out of transaction monitoring on the payment card you entered at any time by contacting support.`}</em></p>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programName`}</inlineCode>{` and `}<inlineCode parentName="li">{`termsConditionsURL`}</inlineCode>{` properties. They are used when building the new USA / Canada specific terms and conditions text that the user must agree with, before linking a card. If you set the `}<inlineCode parentName="li">{`Fidel.country`}</inlineCode>{` property to a USA or Canada, it's mandatory for you to provide your terms and conditions URL via `}<inlineCode parentName="li">{`Fidel.termsConditionsURL`}</inlineCode>{`. If you do not provide it, you will receive an error when you try to open Fidel's Activity (via `}<inlineCode parentName="li">{`onActivityResult`}</inlineCode>{` function).`}</li>
      <li parentName="ul">{`Updated play services auth dependency library to 18.1.0.`}</li>
      <li parentName="ul">{`Fixed some small bugs.`}</li>
    </ul>
    <h2>{`1.3.3`}</h2>
    <ul>
      <li parentName="ul">{`Tapping the Android back button closes the Fidel UI with the same "user cancelled" error, just like tapping the top left X (close) button.`}</li>
    </ul>
    <h2>{`1.3.2`}</h2>
    <ul>
      <li parentName="ul">{`Solved some UI issues.`}</li>
      <li parentName="ul">{`The close button clickable area has been increased.`}</li>
    </ul>
    <h2>{`1.3.1`}</h2>
    <ul>
      <li parentName="ul">{`Updated gradle and some dependencies.`}</li>
    </ul>
    <h2>{`1.3.0`}</h2>
    <ul>
      <li parentName="ul">{`Added new property that lets you define the card schemes that you suppport (`}<inlineCode parentName="li">{`supportedCardSchemes`}</inlineCode>{`).`}</li>
    </ul>
    <h2>{`1.2.3`}</h2>
    <ul>
      <li parentName="ul">{`Added the Canada country option.`}</li>
      <li parentName="ul">{`Remove `}<inlineCode parentName="li">{`allowBackup`}</inlineCode>{` and `}<inlineCode parentName="li">{`supportsRtl`}</inlineCode>{` flags from the SDK's `}<inlineCode parentName="li">{`AndroidManifest.xml`}</inlineCode>{` file to allow you to choose to set them as you'd like.`}</li>
    </ul>
    <h2>{`1.2.2`}</h2>
    <ul>
      <li parentName="ul">{`Added support for linking American Express cards`}</li>
    </ul>
    <h2>{`1.2.1`}</h2>
    <ul>
      <li parentName="ul">{`Added the Japan country option.`}</li>
      <li parentName="ul">{`Hidden the PayPal logo in the card scanning UI.`}</li>
      <li parentName="ul">{`Disabled CardIO manual card details entry forms.`}</li>
      <li parentName="ul">{`Improved testing mode user experience and the overall UX.`}</li>
      <li parentName="ul">{`Allow the user to select the consent checkbox, even before filling in any information.`}</li>
      <li parentName="ul">{`Tapping anywhere on the screen dismisses the keyboard.`}</li>
      <li parentName="ul">{`If you don't set a banner image, we'll hide the top space reserved for it.`}</li>
    </ul>
    <h2>{`1.2.0`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Added the Sweden country option`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Returns an error, when the SDK encounters it, with the `}<inlineCode parentName="p">{`LinkResult`}</inlineCode>{`. Get it with `}<inlineCode parentName="p">{`linkResult.getError()`}</inlineCode>{` getter.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now you can customize the final consent text with the following API:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.companyName = "Your Company Name Inc.";`}</inlineCode>{` (Maximum 60 characters)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.privacyURL = "https://yourcompany.com/privacyURL";`}</inlineCode>{` (must be a valid URL)`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`Fidel.deleteInstructions = "Your delete instructions";`}</inlineCode>{` (Maximum 60 characters)
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the data above is not valid, the UI will not be displayed and you will get an error with the `}<inlineCode parentName="p">{`LinkResult`}</inlineCode>{` in the activity result.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set a default country the SDK should use with `}<inlineCode parentName="p">{`Fidel.country = Fidel.Country.UNITED_KINGDOM`}</inlineCode>{`. When you set a default country, the card linking screen will not show the country picker UI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add support for more test cards. Anything with the following format:`}</p>
        <p parentName="li">{`  Visa: `}<em parentName="p">{`4444000000004***`}</em></p>
        <p parentName="li">{`  Mastercard: `}<em parentName="p">{`5555000000005***`}</em></p>
      </li>
    </ul>
    <h2>{`1.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Added the United States country option`}</li>
      <li parentName="ul">{`Updated gradle and build tools`}</li>
      <li parentName="ul">{`Removed the 'com.afollestad.material-dialogs:core:0.9.4.5' dependency`}</li>
      <li parentName="ul">{`Removed the 'com.koushikdutta.ion:ion:2.+' dependency`}</li>
      <li parentName="ul">{`Doesn't use the Gson library anymore for specifying meta data. Please use the `}<inlineCode parentName="li">{`org.json.JSONObject`}</inlineCode>{` object instead of `}<inlineCode parentName="li">{`com.google.gson.JsonObject`}</inlineCode></li>
      <li parentName="ul">{`More protection against SSL exploits by using Google Play Services. When using the library on an emulator, make sure to use a emulator that has the Google Play Services.`}</li>
      <li parentName="ul">{`Updated `}<inlineCode parentName="li">{`appcompat-v7`}</inlineCode>{` library to version `}<inlineCode parentName="li">{`26.1.0`}</inlineCode></li>
      <li parentName="ul">{`Support the use of all test card numbers. Previously some errors occured while using some of the test cards.`}</li>
      <li parentName="ul">{`Updated checkbox consent text`}</li>
      <li parentName="ul">{`Improved error message when the user tries to link a card that's already linked.`}</li>
      <li parentName="ul">{`Made only the color resources used by the SDK public. The rest of the resources are private now. This will prevent future resource conflicts.`}</li>
      <li parentName="ul">{`Added an error color resource, which you can adjust. It's called `}<inlineCode parentName="li">{`colorError`}</inlineCode>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      